import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import {
  PrototypeAssetsManagerLimits,
  PrototypeRendererProvider,
  PrototypeRendererReact,
  PrototypeTestOverlay,
  WebRendererMode,
  usePrototypeStatus,
  usePrototypeGetArtboardAtPosition,
} from '@sketch-hq/sketch-web-renderer'
import { useContextMenu } from '@sketch/components'

import { ThemeOverride } from '@sketch/global-styles'
import { useDevToolsSetting } from '@sketch/devtools'

import {
  usePresentationManifest,
  usePresentationFile,
  PrototypeContextProvider,
  usePrototypeContext,
  useSyncUrl,
  useKeyboardShortcuts,
  useHighlightHotspots,
  useURLMaps,
  useImageTokens,
  useSyncedUrlPrototypeResizeMode,
  usePrototypeResizeModeShortcuts,
} from './hooks'
import {
  PrototypeLoadStatus,
  PrototypeRendererStatus,
  PrototypeErrorStatus,
  PrototypeLayout,
  ExitFullScreenButton,
  EnterFullScreenButton,
  PrototypeAnnotationsContext,
  PrototypeAnnotationsOverlay,
  PrototypeContextMenu,
} from './components'

import {
  useAnnotationOverlayContext,
  useCanvasAnnotations,
} from '../annotations/hooks'

const backgroundColor = { r: 32 / 255, g: 32 / 255, b: 32 / 255, a: 1 }
const IS_CYPRESS = 'Cypress' in window
const IS_DEV_OR_TEST_ENV =
  process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'test'

const prodAssetManagerLimits: PrototypeAssetsManagerLimits = {
  maxDepthForBackgroundRequests: 2,
  maxArtboardsForBackgroundRequests: 10,
  maxInitialLoadingDepth: 2,
  maxInitialArtboardToLoad: 10,
}

const cypressAssetManagerLimits: PrototypeAssetsManagerLimits = {
  maxDepthForBackgroundRequests: -1,
  maxArtboardsForBackgroundRequests: -1,
  maxInitialLoadingDepth: -1,
  maxInitialArtboardToLoad: -1,
}

/**
 * Only enable this flag in Cypress.
 * @see https://github.com/sketch-hq/Cloud/issues/17507
 * @see https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
 */
const preserveDrawingBuffer = IS_CYPRESS

const CanvasWrapper = styled.div<{ $commentsOpened: boolean }>`
  width: 100%;
  height: 100%;
  /**
    We have some logic in useContextMenu to compensante for this 15px padding
    Be careful when removing or altering this value. Make sure the context menu
    appear in the correct place with and without the right sidebar open
  */
  padding: ${({ $commentsOpened }) => ($commentsOpened ? '15px' : '0')};
  transition: padding 0.3s ease-in-out 0.3s;
  overflow: hidden;

  /*
     The background here is preventing a difference in colours
     when the padding is applied between the page background and the canvas
     background
  */
  background-color: hsl(0, 0%, 13%);
`

/**
 * Web Renderer Prototype Player integration.
 */
export function PrototypeView() {
  return (
    <ThemeOverride theme="dark">
      <PrototypeRendererProvider>
        <PrototypeContextProvider>
          <PrototypeAnnotationsContext>
            <PrototypeLayout>
              <PrototypeViewInner />
            </PrototypeLayout>
          </PrototypeAnnotationsContext>
        </PrototypeContextProvider>
      </PrototypeRendererProvider>
    </ThemeOverride>
  )
}

function PrototypeViewInner() {
  useSyncUrl()
  useKeyboardShortcuts()

  const highlightHotspots = useHighlightHotspots()
  const parentWrapper = useRef<HTMLDivElement>(null)

  const {
    setManifest,
    initialArtboardUUID,
    isRouteParamsValid,
    prototypeStructure,
    manifest,
  } = usePrototypeContext()
  const { resizeMode } = useSyncedUrlPrototypeResizeMode()

  usePrototypeResizeModeShortcuts()

  const [devToolsReleaseMode] = useDevToolsSetting('webRenderer.releaseMode')
  const mode = IS_CYPRESS
    ? WebRendererMode.debug
    : IS_DEV_OR_TEST_ENV
      ? devToolsReleaseMode
        ? WebRendererMode.release
        : WebRendererMode.debug
      : WebRendererMode.release

  const [devToolsShowTilesBorders] = useDevToolsSetting(
    'webRenderer.showTilesBorders'
  )
  const showTilesBorders = IS_DEV_OR_TEST_ENV ? devToolsShowTilesBorders : false

  const [devToolsPrototypeTestOverlay] = useDevToolsSetting(
    'webRenderer.prototypeTestOverlay'
  )
  const prototypeTestOverlay = IS_CYPRESS
    ? true
    : IS_DEV_OR_TEST_ENV && devToolsPrototypeTestOverlay

  const [devToolsAssetManagerLimits] = useDevToolsSetting(
    'webRenderer.prototypeAssetManagerLimits'
  )
  const assetsManagerLimits = useMemo(() => {
    return IS_CYPRESS
      ? cypressAssetManagerLimits
      : IS_DEV_OR_TEST_ENV && devToolsAssetManagerLimits
        ? devToolsAssetManagerLimits
        : prodAssetManagerLimits
  }, [devToolsAssetManagerLimits])

  const rendererStatus = usePrototypeStatus()
  const getArtboardAtPosition = usePrototypeGetArtboardAtPosition()

  const presFile = usePresentationFile({ artboardUuid: initialArtboardUUID })
  const manifestFile = usePresentationManifest(
    presFile.page?.presentationFile?.manifestUrl
  )
  const imageTokens = useImageTokens(manifestFile.status !== 'complete')

  const urlMaps = useURLMaps(
    manifestFile.status === 'complete' ? manifestFile.data : null,
    imageTokens
  )

  useEffect(() => {
    if (manifestFile.status !== 'complete') return
    setManifest(manifestFile.data)
  }, [setManifest, manifestFile])

  // Annotations
  const {
    activeAnnotation,
    isPlacingDraftAnnotation,
    ...annotationPointerEvents
  } = useCanvasAnnotations()

  const context = useAnnotationOverlayContext()
  const areAnnotationVisible =
    context?.status === 'available' && !context.hidden
  const isAnnotationInteractionOnGoing =
    areAnnotationVisible || !!context?.placeDraftAnnotation
  const showShowHighlights =
    highlightHotspots && !isAnnotationInteractionOnGoing

  const [{ handleContextMenu }, contextMenu] = useContextMenu(parentWrapper, {
    contextMenu: PrototypeContextMenu,
    contextMenuProps: {
      initialFrameGroupUUID: initialArtboardUUID,
    },
    onContextMenuState: async ({ x, y }) => {
      // TODO: Rename this to FrameGroup
      // https://linear.app/sketch/issue/SWEB-500
      // Get the artboard where the click happened
      const artboard = await getArtboardAtPosition(x, y)
      const artboardUUID = artboard?.getLayerUUID() || null

      // Get the page identifier the artboard belongs from manifest
      const pageUUID =
        manifest?.contents.pages.find(({ artboards }) =>
          artboards?.find(artboard => artboard.id === artboardUUID)
        )?.id || null

      return { frameGroupUUID: artboardUUID, pageUUID }
    },
  })

  if (presFile.hasError) {
    return (
      <PrototypeErrorStatus
        type={
          presFile.hasNotFoundError ? 'ARTBOARD_NOT_FOUND' : 'GENERIC_SERVER'
        }
      />
    )
  }

  if (presFile.isLoading) {
    return <PrototypeLoadStatus />
  }

  if (presFile.isRendering) {
    return <PrototypeLoadStatus text="Generating" />
  }

  if (manifestFile.status === 'loading') {
    return <PrototypeLoadStatus />
  }

  if (manifestFile.status === 'error') {
    return <PrototypeErrorStatus type="GENERIC_SERVER" />
  }

  if (!prototypeStructure || !urlMaps) {
    return <PrototypeLoadStatus />
  }

  if (!isRouteParamsValid) {
    return <PrototypeErrorStatus type="ARTBOARD_NOT_FOUND" />
  }

  return (
    <CanvasWrapper
      ref={parentWrapper}
      $commentsOpened={isAnnotationInteractionOnGoing}
    >
      <PrototypeRendererStatus status={rendererStatus} />
      <PrototypeRendererReact
        backgroundColor={backgroundColor}
        locateFile={`${process.env.WEB_RENDERER_ASSETS_FOLDER}/{file}`}
        fragmentsURLMap={urlMaps.fragmentsURLMap}
        imagesURLMap={urlMaps.imagesURLMap}
        startArtboardUUID={initialArtboardUUID}
        prototypeStructure={prototypeStructure}
        mode={mode}
        showTilesBorders={showTilesBorders}
        highlightHotspots={showShowHighlights}
        resizeMode={resizeMode}
        assetsManagerLimits={assetsManagerLimits}
        preserveDrawingBuffer={preserveDrawingBuffer}
        containerProps={{
          ...annotationPointerEvents,
          onContextMenu: handleContextMenu,
        }}
        disableInteraction={isAnnotationInteractionOnGoing}
        cursor={() => {
          if (isPlacingDraftAnnotation) {
            return 'copy'
          }

          /**
           * If annotation overlay is visible
           * then the hotspots cursor shouldn't change
           */
          if (areAnnotationVisible) {
            return 'auto'
          }
        }}
      >
        {prototypeTestOverlay && (
          <PrototypeTestOverlay visualize={devToolsPrototypeTestOverlay} />
        )}

        <PrototypeAnnotationsOverlay parentWrapper={parentWrapper} />

        {contextMenu}
      </PrototypeRendererReact>

      <ExitFullScreenButton />
      <EnterFullScreenButton />
    </CanvasWrapper>
  )
}
