import React, { useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'

import { positionDefault } from '@reach/popover'
import { useListboxContext } from '@reach/listbox'
import { shortcuts, useSearch } from '@sketch/utils'
import { routes, RouteParams } from '@sketch/modules-common'

import {
  Dropdown,
  SelectTriggerUnstyled,
  Tooltip,
  TooltipShortcut,
  useForTablet,
  Modal,
  useModalContext,
  Button,
  Box,
  useSearchExpanded,
  SelectInput,
} from '@sketch/components'

// Graphql
import {
  useGetWorkspaceQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import {
  IconButton,
  StyledCheckbox,
  StyledDropdownHeader,
  StyledDropdownItem,
  StyledResetIcon,
  StyledSelectPopover,
  FilterWrapper,
  Filter as SearchInput,
  FilterButton,
  FilterIcon,
} from './SearchBar.styles'
import { useStorageItemsFilters } from '../../../storageItems/hooks'

export const getPlaceholderByPath = (path: string) => {
  switch (path) {
    case routes.WORKSPACE_TRASH.template():
      return 'Search all documents in Trash'
    case routes.WORKSPACE_LIBRARIES.template():
    case routes.DESIGN_SYSTEM.template():
      return 'Search Libraries'
    case routes.WORKSPACE_SHARED_WITH_ME.template():
      return 'Search Shared with Me'
    case routes.WORKSPACE_TEMPLATES.template():
      return 'Search Templates'
    case routes.WORKSPACE_ARCHIVE.template():
      return 'Search archived projects'
    case routes.WORKSPACE_DRAFTS.template():
      return 'Search My Drafts'
    default:
      return 'Search'
  }
}

const SyncFiltersVisibilityToContext: React.FC = () => {
  const { isExpanded } = useListboxContext()
  const { setFiltersVisible } = useSearchExpanded()

  React.useEffect(() => {
    setFiltersVisible(isExpanded)
  }, [isExpanded, setFiltersVisible])

  return null
}

const FiltersMobileModal: React.FC = ({ children }) => {
  const { hideModal } = useModalContext()

  return (
    <Modal onCancel={hideModal}>
      <Modal.Body>
        {children}
        <Box pt={6}>
          <Button variant="secondary" fill onClick={hideModal}>
            Done
          </Button>
        </Box>
      </Modal.Body>
    </Modal>
  )
}

interface FiltersProps {
  userCanToggleProjects: boolean
}

const Filters: React.FC<FiltersProps> = ({
  userCanToggleProjects,
}: FiltersProps) => {
  const isTabletAndBigger = useForTablet()
  const { showModal } = useModalContext()
  const { params } = useRouteMatch<RouteParams<'WORKSPACE_SHARES'>>()

  const { filters, hasFilters, toggleFilterFlag, clearFilters } =
    useStorageItemsFilters()
  const { search } = useSearch()

  const workspaceId = params.workspaceId

  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: workspaceId },
    fetchPolicy: 'cache-only',
  })

  const isArchiveEnabled =
    workspaceData?.workspace.features.archiveEnabled ?? false

  const reset = () => {
    clearFilters()
  }

  const resetActive = hasFilters

  const filterContent = (
    <>
      <StyledDropdownHeader>
        <span>Documents</span>
        <Tooltip content="Reset" placement="top" disableWhenTouchDevice>
          <IconButton onClick={reset}>
            <StyledResetIcon $active={resetActive} />
          </IconButton>
        </Tooltip>
      </StyledDropdownHeader>

      <StyledDropdownItem>
        <StyledCheckbox
          checked={!filters.documents}
          label="All Documents"
          onChange={() => clearFilters()}
        />
      </StyledDropdownItem>

      <StyledDropdownItem>
        <StyledCheckbox
          checked={!!filters.documents?.withPrototypes}
          label="With Prototypes"
          onChange={() => toggleFilterFlag('with-prototypes')}
        />
      </StyledDropdownItem>

      <StyledDropdownItem>
        <StyledCheckbox
          checked={!!filters.documents?.userCanEdit}
          label="You can edit"
          onChange={() => toggleFilterFlag('user-can-edit')}
        />
      </StyledDropdownItem>

      <Switch>
        <Route
          path={[
            routes.WORKSPACE_SHARES.template(),
            routes.WORKSPACE_LIBRARIES.template(),
            routes.WORKSPACE_SHARED_WITH_ME.template(),
            routes.WORKSPACE_TEMPLATES.template(),
          ]}
        >
          {userCanToggleProjects && (
            <>
              <Dropdown.Divider />

              <StyledDropdownItem>
                <StyledCheckbox
                  checked={filters.includeDocumentsInProjects}
                  label="Include documents in projects"
                  onChange={() =>
                    toggleFilterFlag('ignore-documents-in-projects')
                  }
                />
              </StyledDropdownItem>

              {isArchiveEnabled && (
                <StyledDropdownItem>
                  <StyledCheckbox
                    checked={filters.includeArchivedDocuments || !!search}
                    disabled={!!search}
                    label="Include archived documents"
                    onChange={() =>
                      toggleFilterFlag('include-archived-documents')
                    }
                  />
                </StyledDropdownItem>
              )}
            </>
          )}
        </Route>

        <Route
          path={[
            routes.WORKSPACE_PROJECT.template(),
            routes.WORKSPACE_TRASH_PROJECT.template(),
            routes.WORKSPACE_DRAFTS.template(),
          ]}
          exact
        >
          <Dropdown.Divider />
          <StyledDropdownItem>
            <StyledCheckbox
              checked={!filters.searchIgnoringNestedFolders}
              label="Search in Folders"
              help="Documents in folders will appear in search results."
              onChange={() =>
                toggleFilterFlag('search-ignoring-nested-folders')
              }
            />
          </StyledDropdownItem>
        </Route>
      </Switch>
    </>
  )

  // show modal if mobile
  if (!isTabletAndBigger) {
    return (
      <FilterWrapper>
        <SelectInput>
          {({ isExpanded }) => (
            <Tooltip content="Filter" placement="bottom" disableWhenTouchDevice>
              <FilterButton
                $isActive={isExpanded || hasFilters}
                data-testid="filter-dropdown"
                onClick={() =>
                  showModal(FiltersMobileModal, {
                    children: filterContent,
                  })
                }
              >
                <FilterIcon />
              </FilterButton>
            </Tooltip>
          )}
        </SelectInput>
      </FilterWrapper>
    )
  }

  return (
    <SelectInput
      data-testid="filter-dropdown"
      value="Filter"
      onKeyUp={() => null}
    >
      {({ isExpanded }) => (
        <>
          <SyncFiltersVisibilityToContext />
          <SelectTriggerUnstyled>
            <FilterWrapper>
              <Tooltip
                content="Filter"
                placement="bottom"
                disabled={isExpanded}
                disableWhenTouchDevice
              >
                <FilterButton
                  data-testid="filter-dropdown"
                  $isActive={isExpanded || hasFilters}
                >
                  <FilterIcon />
                </FilterButton>
              </Tooltip>
            </FilterWrapper>
          </SelectTriggerUnstyled>
          <StyledSelectPopover position={positionDefault}>
            {filterContent}
          </StyledSelectPopover>
        </>
      )}
    </SelectInput>
  )
}

export interface SearchBarProps {
  userRole: WorkspaceMinimalFragment['userRole']
  fullWidth?: boolean
}

export const SearchBar: React.FC<SearchBarProps> = ({
  userRole,
  fullWidth,
}: SearchBarProps) => {
  const { search, setSearch } = useSearch()
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const userCanToggleProjects = userRole !== 'GUEST'
  const { path } = useRouteMatch()
  const inputPlaceholder = getPlaceholderByPath(path)

  return (
    <Tooltip
      content={
        <>
          Search
          <TooltipShortcut>
            Press {shortcuts.search.toUpperCase()}
          </TooltipShortcut>
        </>
      }
      disableWhenTouchDevice
      placement="bottom-start"
      visible={tooltipVisible}
    >
      <div
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <SearchInput
          onChange={setSearch}
          value={search}
          name="search"
          placeholder={inputPlaceholder}
          fullWidth={!!fullWidth}
        >
          <Route
            path={[
              routes.WORKSPACE_SHARES.template(),
              routes.WORKSPACE_DRAFTS.template(),
              routes.WORKSPACE_LIBRARIES.template(),
              routes.WORKSPACE_SHARED_WITH_ME.template(),
              routes.WORKSPACE_TEMPLATES.template(),
              routes.WORKSPACE_PROJECT.template(),
              routes.WORKSPACE_TRASH_PROJECT.template(),
              routes.WORKSPACE_COLLECTION.template(),
              routes.WORKSPACE_TRASH_COLLECTION.template(),
            ]}
          >
            <div onMouseEnter={() => setTooltipVisible(false)}>
              <Filters userCanToggleProjects={userCanToggleProjects} />
            </div>
          </Route>
        </SearchInput>
      </div>
    </Tooltip>
  )
}
