import styled from 'styled-components'

import {
  ErrorMessage,
  Flex,
  LinkButton,
  Text,
  BaseButton,
} from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const StyledErrorMessage = styled(ErrorMessage)`
  max-width: 448px;
  flex: 0 0 auto;

  padding: 24px;

  ${breakpoint('sm')`
    padding: 32px;
  `}

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  border-radius: ${({ theme }) => theme.radii.large};

  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 4px 8px 0 rgba(0, 0, 0, 0.08),
    0 0 4px 0 rgba(0, 0, 0, 0.08);

  ${ErrorMessage.Title} {
    font-size: 1.25rem;
    font-weight: 600;
  }
`

export const StyledLinkButton = styled(LinkButton)``

export const ButtonWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 17px;

  ${StyledLinkButton}, ${BaseButton} {
    margin: 0;
  }
`

export const BottomText = styled(Text)`
  margin: 24px auto 0;
  text-align: center;
`
